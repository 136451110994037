import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SEO from "../components/seo"

import { Bar, Item } from "../components/ui/tabs"
import step1 from "../images/about/step1.svg"
import step2 from "../images/about/step2.svg"
import step3 from "../images/about/step3.svg"

export default function howToBook({ location }) {
  return (
    <>
      <SEO title="How To Book" />
      <Page>
        <div className="container">
          <h1>About Us</h1>
          <p className="header-text">
            TravelbyBit is an online travel booking platform built for the
            blockchain generation.
            <br />
            We also help travellers and tourists experience the world using
            digital currencies like Bitcoin. We enable users to spend their
            digital currency at a growing network of retail, dining, and
            activity service providers
          </p>
        </div>
        <Bar>
          <Container>
            <Link to="/how-to-book">
              <Item selected={location.pathname === "/how-to-book"}>
                <h3>HOW TO BOOK</h3>
              </Item>
            </Link>
            <Link to="/merchants">
              <Item selected={location.pathname === "/merchants"}>
                <h3>MERCHANTS</h3>
              </Item>
            </Link>
          </Container>
        </Bar>
        <div className="container">
          <div className="content">
            <h2>HOW TO BOOK YOUR TRIP ON TRAVELBYBIT</h2>
            <div className="line" />
            <div className="steps">
              <div className="step step-1">
                <img src={step1} alt="TravelByBit" />
                <h5>Step 1:</h5>
                <p>Search for your trip</p>
              </div>
              <div className="step step-2">
                <img src={step2} alt="TravelByBit" />
                <h5>Step 2:</h5>
                <p>Review your booking</p>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}

const Page = styled.div`
  min-height: calc(100vh - 65px);
  color: #333;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
  }

  .header-text {
    margin: 0.5rem 0 2rem 0;
    line-height: 1.625;

    br {
      margin: 0.5rem 0;
    }
  }

  .content {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0px 15px 5px rgba(0, 0, 0, 0.07);
    padding: 2rem 2rem;
    margin: 2rem 0 3rem 0;

    h2 {
      text-align: center;
    }

    .line {
      height: 1px;
      width: 100%;
      background: rgba(3, 3, 3, 0.2);
      margin: 2rem 0;
    }
  }

  .steps {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 859px) {
      flex-direction: column;
      align-items: center;

      .step:first-child {
        h5 {
          margin-top: -2em;
        }
      }

      p {
        margin-bottom: 1rem;
      }
    }
  }

  .step {
    width: 8em;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #333;

    h5 {
      margin-top: 1rem;
    }

    img {
      height: 7em;
      width: auto;
    }
  }
`

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1170px;
`
